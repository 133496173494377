import { EVENTS, handleMessage, initEvents } from "@/__main__/ipc-core.mjs";
import { setRoute } from "@/__main__/router.mjs";
import { setVolatileKV } from "@/app/actions.mjs";
import eventBus from "@/app/app-event-bus.mjs";
import { GAME_SYMBOL_MARVEL_RIVALS } from "@/game-marvel-rivals/definition-symbol.mjs";
import {
  EVENT_MARVEL_RIVALS_EXIT_GAME,
  EVENT_MARVEL_RIVALS_GAME_START,
  EVENT_MARVEL_RIVALS_HEARTBEAT,
} from "@/game-marvel-rivals/events.mjs";
import { devDebug } from "@/util/dev.mjs";
import { findGameSymbol } from "@/util/game-route.mjs";

type HeartbeatPayload = {
  sessionId: string;
  steamId?: number;
  type: string;
};

type IsRunningPayload = {
  isRunning: boolean;
  steamId?: number;
};

async function initMarvelRivals() {
  await initEvents;

  handleMessage(EVENTS.MARVEL_RIVALS_HEARTBEAT, (payload: HeartbeatPayload) => {
    devDebug("[Marvel Rivals] Received MARVEL_RIVALS_HEARTBEAT", payload);
    eventBus.emit(EVENT_MARVEL_RIVALS_HEARTBEAT, payload);
  });

  handleMessage(
    EVENTS.MARVEL_RIVALS_IS_RUNNING,
    (payload: IsRunningPayload) => {
      const { isRunning, steamId } = payload;
      devDebug("[Marvel Rivals] Received MARVEL_RIVALS_IS_RUNNING", payload);
      if (!isRunning) {
        setVolatileKV("currentGameTuple", [GAME_SYMBOL_MARVEL_RIVALS]);
        eventBus.emit(EVENT_MARVEL_RIVALS_EXIT_GAME);
        return;
      }

      setVolatileKV("currentGameTuple", [GAME_SYMBOL_MARVEL_RIVALS]);
      eventBus.emit(EVENT_MARVEL_RIVALS_GAME_START, { steamId });
      if (findGameSymbol() !== GAME_SYMBOL_MARVEL_RIVALS) {
        setRoute("/marvel-rivals/tierlist");
      }
    },
  );
}

export default initMarvelRivals;
